import React from 'react';
import {Link} from 'gatsby';
import {Row, Col} from 'react-bootstrap';
import Layout from 'layouts';
import {Article} from 'components/site';

export default (props) => {
    return (
      <Layout {...props} >
      <Row noGutters style={{padding: '45px 0px 45px 0px', backgroundColor: '#f7f8f8'}}>
        <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
          {props.pageContext.articles && props.pageContext.articles.map((article, i) => (
            <Article {...article} />
          ))}
        </Col>
      </Row>
      </Layout >
    )
}
